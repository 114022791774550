import React, { useState } from "react";
import Create from "../Components/Create";
import View from "../Components/View";
import "../CSS/Home.css";

const Home = () => {
  const [contentCreate, setContentCreate] = useState("view");
  // const contentHandler = (event) => {
  //   setContentCreate(event);
  // };
  return (
    <>
      {contentCreate === "view" ? <View /> : ""}
      {contentCreate === "create" ? <Create /> : ""}
    </>
  );
};

export default Home;
