import React from "react";
import "../CSS/AddThemes.css";
import { ImUpload } from "react-icons/im";
import { AiOutlinePlusCircle } from "react-icons/ai";
const AddThemes = () => {
  const buttonColors = [
    "#FFF500",
    "#4E0038",
    "#FF7500",
    "#00FC3A",
    "#02C8FF",
    "#A600D2",
  ];
  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="col-md-8">
              <div className="add-question-left">
                <div className="create-Themes-board  d-flex">
                  <div className="col-md-6">
                    <div className="themes-wordscramble">
                      {" "}
                      <h5>SELECT WORD SCRAMBLE THEMES</h5>
                      <h5>CHOOSE COLOR</h5>
                      <div className="d-flex">
                        {buttonColors.length &&
                          buttonColors.map((data, index) => (
                            <button
                              style={{
                                backgroundColor:
                                  buttonColors[index % buttonColors.length],
                                color:
                                  buttonColors[index % buttonColors.length],
                              }}
                            >
                              1
                            </button>
                          ))}
                        <button>
                          <AiOutlinePlusCircle />
                        </button>
                      </div>
                      <h6>Themes</h6>
                    </div>
                  </div>

                  <div className="col-md-6 d-md-flex justify-content-md-end d-flex">
                    <div className="themes-right">
                      <span>
                        Upload:
                        <button>
                          <ImUpload />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="create-preview-board"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddThemes;
