import React from "react";
import Baner from "../assets/Images/Banar.svg";
import { AiOutlineCheck, AiFillEye } from "react-icons/ai";
import "../CSS/Login.css";
import { Link } from "react-router-dom";
const Login = () => {
  return (
    <div className="login-container">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={Baner} alt="Banner_Image" className="img-fluid" />
          </div>
          <div className="col-md-6 right-contents">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div>
                  <h1 className="mb-3">Welcome back.</h1>
                  <p className="mb-0">Login in to your account</p>
                  <span>Enter your username and password below</span>
                </div>
                <form action="#" method="post" className="mt-4">
                  <div className="form-group first mb-3">
                    <label for="username">Enter username</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="username"
                      placeholder="username"
                    />
                    <div className="eye-visible">
                      <AiOutlineCheck />
                    </div>
                  </div>
                  <div className="form-group last mb-4">
                    <label for="password">Password</label>
                    <input
                      type="password"
                      className="form-control mt-2"
                      id="password"
                      placeholder="password"
                    />
                    <div className="eye-visible1">
                      <AiFillEye />
                    </div>
                  </div>

                  <div className="controls">
                    <input
                      type="checkbox"
                      formcontrolname="checkbox"
                      id="exampleCheck1"
                      className="form-check-input"
                    />
                    <label
                      htmlFor="exampleCheck1"
                      className="form-check-label "
                    >
                      Remember Me
                    </label>

                    <span className="text-end">
                      <Link to="#" className="forgot-pass ">
                        Forgot Password
                      </Link>
                    </span>
                  </div>
                  <div className="Signin-Button">
                    <Link to="/profile">
                      <button
                        type="submit"
                        className="btn btn-block btn-warning btn-sm"
                      >
                        LOG IN
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
