import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CSS/WordSearch.css";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { AiFillEdit, AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Wordsearch } from "react-wordsearch";
import "react-wordsearch/dist/index.css";
const WordSearch = () => {
  return (
    <div className="wordsearch-wrapper">
      <div className="col-md-12">
        <div className="crossword-edit d-flex">
          <div className="col time-button">
            <button>
              <i>
                <MdOutlineAccessTimeFilled />
              </i>
              <time>01:00</time>
            </button>
          </div>
          <div className="col text-center wordsearch-title">
            <h6>Fruits name</h6>
            <span>Find the word and draw the circle around it.</span>
          </div>
          <div className="col d-md-flex justify-content-md-end">
            <Link to="/wordsearchpreview">
              {" "}
              <button id="Edit-button">
                <i>
                  <AiFillEdit />
                </i>
              </button>
            </Link>
            <Link to="/homeview">
              <button id="close-circle">
                {" "}
                <i>
                  <AiFillCloseCircle />
                </i>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="wordsearch-wrapper">
        <Wordsearch />
      </div>
      <div className="mt-3">
        <button id="Cross-word-Submit">Submit</button>
      </div>
    </div>
  );
};

export default WordSearch;
