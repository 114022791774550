import React from "react";
import "../CSS/Popup.css";
import "bootstrap/dist/css/bootstrap.min.css";

const DeletePopUp = (props) => {
  return (
    <div className="open_file_container container-fluid ">
      <div>
        <h5>Are you sure you want to delete the puzzle?</h5>
      </div>
      <div className="d-flex ">
        <button id="cancel" onClick={props.handleClose}>
          Cancel
        </button>
        <button id="delete" onClick={props.handleClose}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeletePopUp;
