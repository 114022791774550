import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CSS/CrossWord.css";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { AiFillEdit, AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import Crossword, { ThemeProvider } from "@jaredreisinger/react-crossword";
import Number from "../assets/Images/Number.png";
const CrossWord = () => {
  const data = {
    /* ... puzzle data (see below) ... */

    across: {
      1: {
        answer: "ABSOLUTE",
        row: 0,
        col: 1,
      },
      3: {
        answer: "JUICE",
        row: 2,
        col: 4,
      },
      5: {
        answer: "SPICEJAR",
        row: 6,
        col: 2,
      },
    },
    down: {
      2: {
        answer: "EVER",
        row: 0,
        col: 8,
      },
      4: {
        answer: "OBJECTION",
        row: 0,
        col: 4,
      },
    },
  };
  return (
    <>
      <div className="crossWord-wrapper">
        <div className="col-md-12">
          <div className="crossword-edit d-flex">
            <div className="col time-button">
              <button>
                <i>
                  <MdOutlineAccessTimeFilled />
                </i>
                <time>01:00</time>
              </button>
            </div>
            <div className="col d-md-flex justify-content-md-end">
              <Link to="/crosswordpreview">
                {" "}
                <button id="Edit-button">
                  <i>
                    <AiFillEdit />
                  </i>
                </button>
              </Link>
              <Link to="/homeview">
                <button id="close-circle">
                  {" "}
                  <i>
                    <AiFillCloseCircle />
                  </i>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="col col-md-12 d-flex mt-4 number-crossword">
          <h5>Number CrossWord</h5>
          <img src={Number} alt="" className="Number_Img" />
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
              width: "35em",
            }}
          >
            <ThemeProvider
              theme={{
                allowNonSquare: false,
                columnBreakpoint: "9999px",
                gridBackground: " #91c1d4",
                cellBackground: "#fff",
                cellBorder: " #91c1d4",
                textColor: "#225A6F",
                numberColor: "rgba(0,0,0, 0.25)",
                numberColor: "#9f9",
                highlightBackground: "#F0AC20",
                focusBackground: " #91c1d4",
              }}
            >
              <Crossword data={data} />
            </ThemeProvider>
            <img src={Number} alt="" className="Number_Img1" />
          </div>
        </div>
        <button id="Cross-word-Submit">Submit</button>
      </div>
    </>
  );
};

export default CrossWord;
