import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { FaRegUser, FaSignOutAlt, FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import notification from "../assets/Images/Notification.svg";
import userprofile from "../assets/Images/User_Profile.png";

import "../CSS/Topnav.css";

const CreateBarsNav = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="navbar-top">
        <Container>
          <Navbar.Brand href="#">
            <Link to="/createpuzzle">
              <button className="edit-puzzle-home-Icon">
                <FaBars />
              </button>
            </Link>
          </Navbar.Brand>
          <p className="word-type">Word scramble (Spelling)</p>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link href="#deets" className="notification-Img">
                <img
                  src={notification}
                  alt="Banner_Image"
                  className="img-fluid"
                />
              </Nav.Link>
              <Nav className="profile-dropdown">
                <Nav.Link href="#deets" className="notification-Img">
                  <img
                    src={userprofile}
                    alt="Banner_Image"
                    className="img-fluid"
                  />
                </Nav.Link>
                <Nav.Link eventKey={2} href="#memes">
                  <span>
                    Hello, <b>Peterson</b>
                  </span>
                  <Nav className="drop-down-menu">
                    <NavDropdown
                      title="Content Writer"
                      id="dropdown-basic-button"
                    >
                      <NavDropdown.Item href="/profile">
                        <i>
                          <FaRegUser />
                        </i>
                        Profile
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="#signout">
                        <i>
                          <FaSignOutAlt />
                        </i>
                        Sign Out
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Nav.Link>
              </Nav>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default CreateBarsNav;
