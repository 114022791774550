import React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import { RiDeleteBinLine } from "react-icons/ri";
import { FiPlusCircle } from "react-icons/fi";

const EditPuzzle = () => {
  return (
    <>
      <div className="CreatePuzzle_wrapper">
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item" id="create-puzzle-data">
                  <p className="nav-link active" aria-current="page" href="#">
                    Create puzzle
                  </p>
                </li>

                <li className="nav-item " id="question-edit">
                  <p
                    className="nav-link active text-decoration-underline"
                    aria-current="page"
                  >
                    Question
                  </p>
                </li>
                <li className="nav-item" id="themes">
                  <p className="nav-link " aria-current="page" href="#">
                    Themes
                  </p>
                </li>
                <li className="nav-item" id="duration">
                  <p className="nav-link " aria-current="page" href="#">
                    Duration:
                    <input type="time" value="10:05 AM" />
                  </p>
                </li>
                <li className="nav-item" id="preview">
                  <p className="nav-link " aria-current="page" href="#">
                    Preview
                  </p>
                </li>
                {/* <li className="nav-item" id="preview-close">
                  <p className="nav-link " aria-current="page" href="#">
                    <i>
                      <AiFillCloseCircle />
                    </i>
                  </p>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
        <div>
          <div className="row">
            <div className="col col-md-3 mt-1">
              <div className="create-puzzle-data">
                <div className="form-Dropdown">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Subject
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Subject"
                    >
                      <MenuItem value={10}>English</MenuItem>
                      <MenuItem value={20}>Maths</MenuItem>
                      <MenuItem value={30}>Physics</MenuItem>
                      <MenuItem value={30}>Biology</MenuItem>
                      <MenuItem value={30}>Chemistery</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Subject"
                    >
                      <MenuItem value={10}>Grade 1</MenuItem>
                      <MenuItem value={20}>Grade 2</MenuItem>
                      <MenuItem value={30}>Grade 3</MenuItem>
                      <MenuItem value={30}>Grade 4</MenuItem>
                      <MenuItem value={30}>Grade 5</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Categories
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Categories"
                    >
                      <MenuItem value={10}>Spelling</MenuItem>
                      <MenuItem value={20}>Grammer</MenuItem>
                      <MenuItem value={30}>Punctuation</MenuItem>
                      <MenuItem value={30}>Season Activity</MenuItem>
                      <MenuItem value={30}>Comprehensive</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Type"
                    >
                      <MenuItem value={10}>Word scramble</MenuItem>
                      <MenuItem value={20}>Crossword</MenuItem>
                      <MenuItem value={30}>Word search</MenuItem>
                      <MenuItem value={30}>Filling the blanks</MenuItem>
                      <MenuItem value={30}>Comprensive booklet</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="create-next-button">
                  <Link to="/createpuzzle">
                    <button>Save</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-9 d-flex">
              <div className="col-md-8">
                <div className="create-question-board">
                  <div>
                    <label for="inputName" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputName"
                      placeholder="Eg: Write down the name of creature who lives in ocean"
                    />
                  </div>
                  <div>
                    <Table bordered className="puzzle-table-crud mt-4">
                      <tbody>
                        <tr>
                          <td id="puzzle-sno">1</td>
                          <td>
                            <button>
                              <FiPlusCircle />
                            </button>
                            <button>
                              <RiDeleteBinLine />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td id="puzzle-sno">2</td>
                          <td>
                            {" "}
                            <button>
                              <FiPlusCircle />
                            </button>
                            <button>
                              <RiDeleteBinLine />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td id="puzzle-sno">3</td>
                          <td>
                            {" "}
                            <button>
                              <FiPlusCircle />
                            </button>
                            <button>
                              <RiDeleteBinLine />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td id="puzzle-sno">4</td>
                          <td>
                            {" "}
                            <button>
                              <FiPlusCircle />
                            </button>
                            <button>
                              <RiDeleteBinLine />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="puzzle-footer d-flex">
                    <div className="col-md-3 d-flex">
                      <button>
                        <FiPlusCircle />
                      </button>
                      <p>Add Question</p>
                    </div>
                    <div className="col-md-3 d-flex">
                      <input
                        type="checkbox"
                        formcontrolname="checkbox"
                        id="exampleCheck1"
                        className="form-check-input"
                      />
                      <label>Show first Letter</label>
                    </div>
                    <div className="col-md-3 d-flex">
                      <input
                        type="checkbox"
                        formcontrolname="checkbox"
                        id="exampleCheck1"
                        className="form-check-input"
                      />
                      <label>Show Last Letter</label>
                    </div>
                    <div className="col-md-3">
                      <button className="puzzle-create-button">Create</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="create-preview-board"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPuzzle;
