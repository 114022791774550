import React from "react";
import "../CSS/Profile.css";
import profilecontent from "../assets/Images/profile_content@2x.png";
const Profile = () => {
  return (
    <>
      <div className="container">
        <div className="profile-container m-4">
          <h4>Welcome !</h4>

          <div className="card">
            <div className="card-header">
              <p>My Details</p>
            </div>
            <div className="card-body">
              <form className="row g-3 profile-form">
                <div className="col-md-2">
                  <img src={profilecontent} alt="" />
                </div>
                <div className="col-md-5">
                  <label for="inputName" className="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Peterson"
                  />
                  <label for="inputName" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Lorem lipsum dolor sit: To edit you will have to switch to the parents' account"
                  />
                  <label for="inputName" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="UK"
                  />
                  <label for="inputName" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="+4432345678"
                  />
                  <label for="inputName" className="form-label">
                    Gender
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Male"
                  />
                </div>

                <div className="col-md-5">
                  <label for="inputName" className="form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Morningstar"
                  />
                  <label for="inputName" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="London"
                  />
                  <label for="inputName" className="form-label">
                    Postel Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="9876543"
                  />
                  <label for="inputName" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="peterson@gmail.com"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
