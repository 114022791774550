import React from "react";

// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import playButton from "../assets/Images/videotoggle.svg";
import deleteIcon from "../assets/Images/deletebutton.svg";
import toggleButton from "../assets/Images/toggleButton.svg";

const View = () => {
  return (
    <>
      <Navbar expand="lg" className="home-Navbar">
        <Nav className="me-auto">
          <Nav.Link href="/homecreate">
            <Button className="create-button">Create</Button>
          </Nav.Link>
          <Nav.Link href="/homeview">
            <Button className="view-button">View</Button>
          </Nav.Link>
        </Nav>
      </Navbar>

      <Navbar
        collapseOnSelect
        expand="lg"
        className="view-wrapper mt-4"
        variant="primary"
      >
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
        <Nav className="me-auto">
          <Nav.Link href="#features">Grade</Nav.Link>
          <Nav.Link href="#pricing">Cataogorie</Nav.Link>
          <Nav.Link href="#pricing">type</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link href="#deets">Demo</Nav.Link>
          <Nav.Link eventKey={2} href="#memes">
            Publish
          </Nav.Link>
          <Nav.Link eventKey={2} href="#memes">
            Delete
          </Nav.Link>
        </Nav>
        {/* </Navbar.Collapse> */}
      </Navbar>

      <Nav className="me-auto d-flex row mt-4">
        <div className="col-md-8 d-flex view-dropdown-form ">
          <div className="col-md-3">
            <Form.Select aria-label="Default select example">
              <option>Select Subject</option>
              <option value="1">English</option>
              <option value="2">Physics</option>
              <option value="3">Chemistery</option>
              <option value="3">Maths</option>
              <option value="3">Biology</option>
            </Form.Select>
          </div>
          <div className="col-md-3">
            <Form.Select aria-label="Default select example">
              <option>Grade</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </div>
          <div className="col-md-3">
            <Form.Select aria-label="Default select example">
              <option>Cataogories</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </div>
          <div className="col-md-3">
            <Form.Select aria-label="Default select example">
              <option>Puzzle type</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </div>
        </div>
      </Nav>
      <Table className=" mt-4 border View_Table_Button" responsive>
        <tbody>
          <tr>
            <td>Grade 1</td>
            <td>Spelling</td>
            <td id="type_data">Word scramble</td>
            <td>U1</td>
            <td>A1</td>
            <td>A2</td>
            <td>
              {" "}
              <img src={playButton} alt="Play_Button" />
            </td>
            <td>
              {" "}
              <img src={toggleButton} alt="Play_Button" />
              {/* <div class="switch_box box_1">
                  <input
                    type="checkbox"
                    class="switch_1"
                    name="payment_push_notification"
                  />
                </div> */}
            </td>
            <td>
              <img src={deleteIcon} alt="Delete_Icon" />
            </td>
          </tr>
          <tr>
            <td>Grade 2</td>
            <td>Spelling</td>
            <td id="type_data">Cross Word</td>
            <td>U1</td>
            <td>A2</td>
            <td></td>
            <td>
              {" "}
              <Link to="/crossword">
                {" "}
                <img src={playButton} alt="Play_Button" />
              </Link>
            </td>
            <td>
              {" "}
              <img src={toggleButton} alt="Play_Button" />
            </td>
            <td>
              <img src={deleteIcon} alt="Delete_Icon" />
            </td>
          </tr>
          <tr>
            <td>Grade 1</td>
            <td>Grammer</td>
            <td id="type_data">WordSearch</td>
            <td>U1</td>
            <td>Le1</td>
            <td>A2</td>
            <td>
              {" "}
              <Link to="/wordsearch">
                {" "}
                <img src={playButton} alt="Play_Button" />
              </Link>
            </td>
            <td>
              {" "}
              <img src={toggleButton} alt="Play_Button" />
            </td>
            <td>
              <img src={deleteIcon} alt="Delete_Icon" />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default View;
