import "./App.css";
import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//List of Navbars
import AccessTopnav from "./Navbar/AccessTopnav";
import CreatePuzzleNav from "./Navbar/CreatePuzzleNav";
import ViewNavbar from "./Navbar/ViewNavbar";
import EditPuzzleNav from "./Navbar/EditPuzzleNav";
import CreateBarsNav from "./Navbar/CreateBarsNav";
//List Of Pages
import Login from "./Pages/Login";
import Profile from "./Pages/Profile";
import Home from "./Pages/Home";
import EditPuzzle from "./Pages/EditPuzzle";
import AddQuestion from "./Pages/AddQuestion";
//Home Componets
import Create from "./Components/Create";
import CreatePuzzle from "./Components/CreatePuzzle";
import View from "./Components/View";
//Questions List Pages
import CrossWord from "./Questions/CrossWord";
import CrossWordPreview from "./Preview/CrossWordPreview";
import WordSearch from "./Questions/WordSearch";
import WordSearchPreview from "./Preview/WordSearchPreview";
const NavbarAccess = ({ component: Component, ...rest }) => (
  <div>
    <AccessTopnav />
    <Component />
  </div>
);
const NavbarView = ({ component: Component, ...rest }) => (
  <div>
    <ViewNavbar />
    <Component />
  </div>
);
const NavbarPuzzle = ({ component: Component, ...rest }) => (
  <div>
    <CreatePuzzleNav />
    <Component />
  </div>
);
const NavbarEditPuzzle = ({ component: Component, ...rest }) => (
  <div>
    <EditPuzzleNav />
    <Component />
  </div>
);
const NavbarAddQuestion = ({ component: Component, ...rest }) => (
  <div>
    <CreateBarsNav />
    <Component />
  </div>
);
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          {/* Main Navbar Route */}
          <Route
            path="/profile"
            element={<NavbarAccess component={Profile} />}
          />
          <Route path="/home" element={<NavbarAccess component={Home} />} />
          {/* View Navabar Routes */}
          <Route
            path="/homecreate"
            element={<NavbarView component={Create} />}
          />
          <Route path="/homeview" element={<NavbarView component={View} />} />
          {/* Create Puzzle Navbar */}
          <Route
            path="/createpuzzle"
            element={<NavbarPuzzle component={CreatePuzzle} />}
          />
          {/* Edit Puzzle Navbar */}
          <Route
            path="/editpuzzle"
            element={<NavbarEditPuzzle component={EditPuzzle} />}
          />
          {/*ADD Question Routes*/}
          <Route
            path="/addquestion"
            element={<NavbarAddQuestion component={AddQuestion} />}
          />
          <Route path="/crossword" element={<CrossWord />} />
          <Route
            path="/crosswordpreview"
            element={<NavbarAddQuestion component={CrossWordPreview} />}
          />
          <Route path="/wordsearch" element={<WordSearch />} />
          <Route
            path="/wordsearchpreview"
            element={<NavbarAddQuestion component={WordSearchPreview} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
