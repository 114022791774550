import React, { useState } from "react";

import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import AddThemes from "./AddThemes";
import Popup from "./Popup";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiPlusCircle } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";
import "../CSS/AddQuestion.css";
const AddQuestion = () => {
  const [question, setQuestion] = useState("question");
  const [state, setState] = useState(false);
  const [count, setCount] = useState(0);
  const [hint, setHint] = useState(false);
  const questionHandler = (e) => {
    setQuestion(e);
  };
  const addHint = () => {
    setHint(!hint);
  };
  return (
    <>
      {question === "question" ? (
        <div className="CreatePuzzle_wrapper">
          <nav className="navbar navbar-expand-lg navbar-addquestion">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item " id="add-question-edit">
                    <p
                      className="nav-link active text-decoration-underline list active"
                      aria-current="page"
                      onClick={() => questionHandler("question")}
                      id="question"
                    >
                      Question
                    </p>
                  </li>
                  <li className="nav-item" id="themes">
                    <p
                      className="nav-link list "
                      aria-current="page"
                      onClick={() => questionHandler("themes")}
                      id="themes"
                    >
                      Themes
                    </p>
                  </li>
                  <li className="nav-item" id="duration-add-question">
                    <p className="nav-link " aria-current="page" href="#">
                      Duration:
                      <input type="time" value="10:05 AM" />
                    </p>
                  </li>
                  <li className="nav-item" id="preview-add-question">
                    <p className="nav-link " aria-current="page" href="#">
                      Preview
                    </p>
                  </li>
                  <li className="nav-item" id="preview-close-add-question">
                    <Link to="/homecreate">
                      {" "}
                      <p className="nav-link " aria-current="page" href="#">
                        <i>
                          <AiFillCloseCircle />
                        </i>
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div>
            <div className="row">
              <div className="col-md-12 d-flex">
                <div className="col-md-8">
                  <div className="add-question-left">
                    <div className="create-question-board">
                      <div className=" d-flex">
                        <div className="col-md-6">
                          <label for="inputName" className="form-label">
                            Select Unit
                          </label>
                          <Form.Select
                            aria-label="Default select example"
                            placeholder="Ex: Unit1"
                          >
                            <option>Level 1</option>
                            <option>Level 2</option>
                            <option>Level 3</option>
                            <option>Level 4</option>
                          </Form.Select>
                        </div>
                        <div className="col-md-6">
                          <label for="inputName" className="form-label">
                            Activity no
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputName"
                            placeholder="Peterson"
                          />
                        </div>
                      </div>
                      <div>
                        <label for="inputName" className="form-label">
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputName"
                          placeholder="Eg: Write down the name of creature who lives in ocean"
                        />
                      </div>
                      <div>
                        <Table bordered className="puzzle-table-crud mt-4">
                          <tbody>
                            <tr>
                              <td id="puzzle-sno">1</td>
                              <td>
                                {state ? (
                                  <span>Fish</span>
                                ) : (
                                  <span onClick={() => setState(true)}>
                                    click
                                  </span>
                                )}
                                <button>
                                  <RiDeleteBinLine />
                                </button>
                                <button onClick={addHint}>
                                  <FiPlusCircle />
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td id="puzzle-sno">2</td>
                              {/* <td>
                                {" "}
                                <button>
                                  <FiPlusCircle />
                                </button>
                                <button>
                                  <RiDeleteBinLine />
                                </button>
                              </td> */}
                              <td>
                                {state ? (
                                  <span>Cat</span>
                                ) : (
                                  <span onClick={() => setState(true)}></span>
                                )}
                                <button>
                                  <RiDeleteBinLine />
                                </button>
                                <button>
                                  <FiPlusCircle />
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td id="puzzle-sno">3</td>
                              <td>
                                {" "}
                                <button>
                                  <RiDeleteBinLine />
                                </button>
                                <button>
                                  <FiPlusCircle />
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td id="puzzle-sno">4</td>
                              <td>
                                {" "}
                                <button>
                                  <RiDeleteBinLine />
                                </button>
                                <button>
                                  <FiPlusCircle />
                                </button>
                              </td>
                            </tr>
                            {count ? (
                              <tr>
                                <td id="puzzle-sno">{count + 4}</td>
                                <td>
                                  {" "}
                                  <button>
                                    <RiDeleteBinLine />
                                  </button>
                                  <button>
                                    <FiPlusCircle />
                                  </button>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </Table>
                        {hint && (
                          <Popup
                            content={
                              <>
                                <b>Design your Popup</b>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                  Excepteur sint occaecat cupidatat non
                                  proident, sunt in culpa qui officia deserunt
                                  mollit anim id est laborum.
                                </p>
                                <button>Test button</button>
                              </>
                            }
                            handleClose={addHint}
                          />
                        )}
                      </div>
                      <div className="puzzle-footer d-flex">
                        <div
                          className="col-md-3 d-flex"
                          onClick={() => setCount(count + 1)}
                        >
                          <button>
                            <FiPlusCircle />
                          </button>
                          <p>Add Question</p>
                        </div>
                        <div className="col-md-3 d-flex">
                          <input
                            type="checkbox"
                            formcontrolname="checkbox"
                            id="exampleCheck1"
                            className="form-check-input"
                          />
                          <label>Show first Letter</label>
                        </div>
                        <div className="col-md-3 d-flex">
                          <input
                            type="checkbox"
                            formcontrolname="checkbox"
                            id="exampleCheck1"
                            className="form-check-input"
                          />
                          <label>Show Last Letter</label>
                        </div>
                        <div className="col-md-3">
                          <button className="puzzle-create-button">
                            Create
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="create-preview-board"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {question === "themes" ? (
        <div className="CreatePuzzle_wrapper">
          <nav className="navbar navbar-expand-lg navbar-addquestion">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item " id="add-question-edit">
                    <p
                      className="nav-link active text-decoration-underline"
                      aria-current="page"
                      onClick={() => questionHandler("question")}
                    >
                      Question
                    </p>
                  </li>
                  <li className="nav-item" id="themes">
                    <p
                      className="nav-link "
                      aria-current="page"
                      onClick={() => questionHandler("themes")}
                    >
                      Themes
                    </p>
                  </li>
                  <li className="nav-item" id="duration-add-question">
                    <p className="nav-link " aria-current="page" href="#">
                      Duration:
                      <input type="time" value="10:05 AM" />
                    </p>
                  </li>
                  <li className="nav-item" id="preview-add-question">
                    <p className="nav-link " aria-current="page" href="#">
                      Preview
                    </p>
                  </li>
                  <li className="nav-item" id="preview-close-add-question">
                    <Link to="/homecreate">
                      {" "}
                      <p className="nav-link " aria-current="page" href="#">
                        <i>
                          <AiFillCloseCircle />
                        </i>
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <AddThemes />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AddQuestion;
