import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import DeletePopUp from "../Popups/DeletePopUp";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiPlusCircle } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";
import "../CSS/WordSearch.css";
import { Wordsearch } from "react-wordsearch";
import "react-wordsearch/dist/index.css";

const WordSearchPreview = () => {
  const [state, setState] = useState(false);
  const [count, setCount] = useState(0);
  const [remove, setRemove] = useState(false);

  const deleteword = () => {
    setRemove(!remove);
  };
  return (
    <>
      <div className="CreatePuzzle_wrapper">
        <nav className="navbar navbar-expand-lg navbar-addquestion">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item " id="add-question-edit">
                  <p
                    className="nav-link active text-decoration-underline list active"
                    aria-current="page"
                    id="question"
                  >
                    Question
                  </p>
                </li>
                <li className="nav-item" id="themes">
                  <p className="nav-link list " aria-current="page" id="themes">
                    Themes
                  </p>
                </li>
                <li className="nav-item" id="duration-add-question">
                  <p className="nav-link " aria-current="page" href="#">
                    Duration:
                    <input type="time" value="10:05 AM" />
                  </p>
                </li>
                <li className="nav-item" id="preview-add-question">
                  <p className="nav-link " aria-current="page" href="#">
                    Preview
                  </p>
                </li>
                <li className="nav-item" id="preview-close-add-question">
                  <Link to="/homeview">
                    {" "}
                    <p className="nav-link " aria-current="page" href="#">
                      <i>
                        <AiFillCloseCircle />
                      </i>
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div>
          <div className="row">
            <div className="col-md-12 d-flex">
              <div className="col-md-8">
                <div className="add-question-left">
                  <div className="create-question-board">
                    <div>
                      <label for="inputName" className="form-label">
                        Select Type
                      </label>
                      <Form.Select
                        aria-label="Default select example"
                        placeholder="Ex: Unit1"
                      >
                        <option>Level 1</option>
                        <option>Level 2</option>
                        <option>Level 3</option>
                        <option>Level 4</option>
                      </Form.Select>
                    </div>
                    <div className=" d-flex">
                      <div className="col-md-4">
                        <label for="inputName" className="form-label">
                          Select Unit
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          placeholder="Ex: Unit1"
                        >
                          <option>Level 1</option>
                          <option>Level 2</option>
                          <option>Level 3</option>
                          <option>Level 4</option>
                        </Form.Select>
                      </div>

                      <div className="col-md-4">
                        <label for="inputName" className="form-label">
                          Select lesson
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          placeholder="Ex: Unit1"
                        >
                          <option>Level 1</option>
                          <option>Level 2</option>
                          <option>Level 3</option>
                          <option>Level 4</option>
                        </Form.Select>
                      </div>
                      <div className="col-md-4">
                        <label for="inputName" className="form-label">
                          Activity no
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputName"
                          placeholder="1"
                        />
                      </div>
                    </div>
                    <div>
                      <label for="inputName" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        placeholder="Eg: Write down the name of creature who lives in ocean"
                      />
                    </div>
                    <div>
                      <Table bordered className="puzzle-table-crud mt-4">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Words</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td id="puzzle-sno">1</td>
                            <td>
                              {" "}
                              {state ? (
                                <span>Fish</span>
                              ) : (
                                <span onClick={() => setState(true)}>
                                  click
                                </span>
                              )}
                            </td>
                            <td>
                              <button onClick={deleteword}>
                                <RiDeleteBinLine />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td id="puzzle-sno">2</td>
                            <td>
                              {" "}
                              {state ? (
                                <span>Cat</span>
                              ) : (
                                <span onClick={() => setState(true)}></span>
                              )}
                            </td>
                            <td>
                              <button>
                                <RiDeleteBinLine />
                              </button>
                              {/* <button>
                                <FiPlusCircle />
                              </button> */}
                            </td>
                          </tr>
                          <tr>
                            <td id="puzzle-sno">3</td>
                            <td></td>
                            <td>
                              {" "}
                              <button>
                                <RiDeleteBinLine />
                              </button>
                              {/* <button>
                                <FiPlusCircle />
                              </button> */}
                            </td>
                          </tr>
                          <tr>
                            <td id="puzzle-sno">4</td>
                            <td></td>
                            <td>
                              {" "}
                              <button>
                                <RiDeleteBinLine />
                              </button>
                              {/* <button>
                                <FiPlusCircle />
                              </button> */}
                            </td>
                          </tr>
                          {count ? (
                            <tr>
                              <td id="puzzle-sno">{count + 4}</td>
                              <td></td>
                              <td>
                                {" "}
                                <button>
                                  <RiDeleteBinLine />
                                </button>
                                {/* <button>
                                  <FiPlusCircle />
                                </button> */}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </Table>
                      {remove ? <DeletePopUp handleClose={deleteword} /> : ""}
                    </div>
                    <div className="puzzle-footer d-flex">
                      <div
                        className="col-md-6 d-flex"
                        onClick={() => setCount(count + 1)}
                      >
                        <button>
                          <FiPlusCircle />
                        </button>
                        <p>Add Question</p>
                      </div>
                      <div className="col-md-6">
                        <button className="puzzle-Save-button">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="create-preview-board">
                  <div className="Wordsearch-preview">
                    <Wordsearch />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WordSearchPreview;
