import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../CSS/Create.css";
import cat from "../assets/Images/cat-border.svg";
const Create = () => {
  // const [hide, setHide] = useState(false);
  let navigate = useNavigate();
  const clickNextHandler = () => {
    navigate("/createpuzzle");
  };

  return (
    <>
      <div>
        <Navbar collapseOnSelect expand="lg" className="home-Navbar">
          <Nav className="me-auto">
            <Nav.Link href="/homecreate">
              <Button className="view-button">Create</Button>
            </Nav.Link>
            <Nav.Link href="/homeview">
              <Button className="create-button">View</Button>
            </Nav.Link>
          </Nav>
          <Nav className="me-auto create-view-buttons" >
            <Nav.Link
              href="#features"
              id="create-Questions"
              className="text-decoration-underline"
            >
              Questions
            </Nav.Link>
            <Nav.Link href="#features" id="create-themes">
              Themes
            </Nav.Link>
          </Nav>

          <Nav className="me-auto create-duration">
            Duration:{" "}
            <input type="time" className="form-control" value="10:05 AM" />
          </Nav>
          <Nav className="me-auto create-preview">Preview</Nav>
        </Navbar>

        <div className=" mt-2 create-field-body">
          <div className="row">
            <div className="col col-md-3 mt-1">
              <div className="create-Puzzle">
                <p>Create puzzle</p>
                <div className="form-Dropdown">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Subject
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Subject"
                    >
                      <MenuItem value={10}>English</MenuItem>
                      <MenuItem value={20}>Maths</MenuItem>
                      <MenuItem value={30}>Physics</MenuItem>
                      <MenuItem value={30}>Biology</MenuItem>
                      <MenuItem value={30}>Chemistery</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Subject"
                    >
                      <MenuItem value={10}>Grade 1</MenuItem>
                      <MenuItem value={20}>Grade 2</MenuItem>
                      <MenuItem value={30}>Grade 3</MenuItem>
                      <MenuItem value={30}>Grade 4</MenuItem>
                      <MenuItem value={30}>Grade 5</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Categories
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Categories"
                    >
                      <MenuItem value={10}>Spelling</MenuItem>
                      <MenuItem value={20}>Grammer</MenuItem>
                      <MenuItem value={30}>Punctuation</MenuItem>
                      <MenuItem value={30}>Season Activity</MenuItem>
                      <MenuItem value={30}>Comprehensive</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Type"
                    >
                      <MenuItem value={10}>Word scramble</MenuItem>
                      <MenuItem value={20}>Crossword</MenuItem>
                      <MenuItem value={30}>Word search</MenuItem>
                      <MenuItem value={30}>Filling the blanks</MenuItem>
                      <MenuItem value={30}>Comprensive booklet</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="create-next-button">
                  <button onClick={clickNextHandler}>Next</button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="create-Notice-board">
                <img src={cat} alt="" />
                <p>Note: fill all the data to proceed further.</p>
                <p>Note for dev: prototyping is done for type only.</p>
              </div>
            </div>
            <div className="col col-md-3"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Create;
